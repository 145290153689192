import React from "react"
import { Link } from "gatsby"
import { SocialIcon } from "react-social-icons"

import { rhythm, scale } from "../utils/typography"

const FooterIcon: React.FC<{ url: string }> = ({ url }) => (
  <SocialIcon
    url={url}
    style={{
      width: "25px",
      height: "25px",
      boxShadow: "none",
      marginRight: "5px",
    }}
    bgColor="#222222"
  />
)

export const Layout: React.FC<{
  location: Location
  title: string
}> = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let header

  if (location.pathname === rootPath) {
    header = (
      <h1
        style={{
          ...scale(1),
          marginBottom: rhythm(1.5),
          marginTop: 0,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            textDecoration: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h1>
    )
  } else {
    header = (
      <h3
        style={{
          fontFamily: `Montserrat, sans-serif`,
          marginTop: 0,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            textDecoration: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h3>
    )
  }
  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(24),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
      }}
    >
      <header>{header}</header>
      <main>{children}</main>
      <footer
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <span>
          {" "}
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </span>
        <span>
          <FooterIcon url="https://www.twitter.com/bemmel_nu" />
          <FooterIcon url="https://www.github.com/thabemmz" />
          <FooterIcon url="https://www.linkedin.com/in/christiaanvanbemmel/" />
        </span>
      </footer>
    </div>
  )
}
